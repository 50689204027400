<template>
  <div class="success">
    <!-----------------------------------
      Start Header Component
      ------------------------------------>
    <Header></Header>
    <!-----------------------------------
      End Header Component
      ------------------------------------>
    <div class="flex justify-center mb-44 pt-56">
      <div class="shadow-md rounded p-6 lg:w-1/4 mx-5">
        <div class="flex justify-center items-center mb-5">
          <img
            :src="images.valider"
            alt="valider"
            width="48"
            height="48"
            original
          />
        </div>
        <div class="font-medium text-xl text-gray-900 mb-2 text-center">
          {{ $t("candidatureT") }}
        </div>
        <div class="font-normal text-gray-500 mb-5 text-center">
          {{ $t("candidatureP") }}
        </div>
        <div class="flex justify-center" @click="openHome()">
          <button
            class="font-medium text-base text-white bg-greenNew1 rounded-lg cursor-pointer py-2.5 px-4"
          >
            D’accord
          </button>
        </div>
      </div>
    </div>

    <!-----------------------------------
  Start Footer Component
  ------------------------------------>
    <Footer></Footer>
    <!-----------------------------------
  End Footer Component
  ------------------------------------>
  </div>
</template>

<script>
/*Import Components */
import Header from "../../components/header/index.vue";
import Footer from "../../components/footer/index.vue";
import valider from "../../assets/images/img_valider.webp";

export default {
  name: "index",
  components: { Header, Footer },
  data() {
    return {
      icons: {},
      images: { valider },
    };
  },
  methods: {
    openHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";

.img-fill {
  fill: transparent;
}
</style>
